import { acceptHMRUpdate, defineStore } from 'pinia'

export const useLayoutStore = defineStore('layoutStore', {
  state: () => ({
    tutorialTagVisible: true,
    tutorialTagOpened: false,
    tutorialTagHeight: 0,
  }),
  actions: {
    hideTutorialTag() {
      this.tutorialTagVisible = false
    },
    openTutorialTag() {
      this.tutorialTagOpened = true
    },
    setTutorialTagHeight(height: number) {
      this.tutorialTagHeight = height
    },
  },
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot))
}
